<template>
  <div class="t-middle common-tb-120">
     <div>
       <h2>{{ $t("index.thank") }}</h2>
       <img src="~@/assets/img/email-s.png">
     </div>
  </div>
</template>

<script>
export default {
  name: "thankYou"
}
</script>

<style scoped>
.t-middle{
  display: flex;
  align-items: center;
  justify-content: center;
}
.t-middle>div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.t-middle img{
   padding: 40px 0;
  margin: 0 auto;
}
.t-middle h2{
  text-align: center;
}
@media only screen and (min-width:0px) and (max-width:767px){
  .t-middle>div h2{
     font-size: 20px;
     padding: 0 10px;
  }
}
</style>